<template lang="pug">
  .delivery
    Breadcrumb(:current_path="{name: 'Delivery', title: 'Delivery'}")

    .container.delivery__container
      h1.title_2.delivery__title(v-html="$t('pages.delivery.title')")

      .delivery__content
        p.delivery__text(v-html="$t('pages.delivery.text')")
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'

export default {
  name: 'Delivery',
  components: { Breadcrumb }
}
</script>
